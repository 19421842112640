<template>
  <div class="d-flex w-100 h-100 signup reset-temp">
    <div class="sides left-side section-wrapper d-flex flex-column align-items-start justify-content-between">
      <loading :active.sync="isLoading" 
      background-color="#332abb"
      :opacity= 0.99
      color="#fff"
      :is-full-page="fullPage"></loading>

      <form @submit.prevent="submitForm" autocomplete="off" class="w-100 h-100 d-flex flex-column justify-content-center">
        <h2>Şifreni Yenile</h2>
        
          <div class="form-group">
            <label for="userMail">E-Posta</label>
            <input 
              v-model="form.userMail" 
              v-on:keydown='key'
              v-bind:class="{error: $v.form.userMail.$error, valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid}"
              inputmode="email" type="email" class="form-control">
            <p class="form-warning" v-if="!$v.form.userMail.email">
              <span class="svg-icon icons-alert"></span>Yanlış mail formatı</p>
            <p class="form-warning" v-if="!$v.form.userMail.required">
              <span class="svg-icon icons-alert"></span>Bu alan zorunludur.</p>
          </div>


        <div class="form-group">
          <label for="userPass">Şifre</label>
          <input 
            v-model="form.userPass"
            v-bind:class="{error: $v.form.userPass.$error, valid: $v.form.userPass.$dirty && !$v.form.userPass.$invalid}"
            type="password" class="form-control">
          <p class="form-warning" v-if="!$v.form.userPass.required">
            <span class="svg-icon icons-alert"></span> Bu alan zorunludur.</p>
            <p class="form-warn" v-if="wrongInfo">
            <span class="svg-icon icons-alert"></span> Giriş bilgilerinizi kontrol ediniz.</p>
        </div>
        <div class="form-group password position-relative">
          <label for="passRepeat">Şifre Tekrar</label>
          <input class="form-control" type="password"
            v-model="form.passRepeat" 
            v-bind:class="{error: $v.form.passRepeat.$error, valid: $v.form.passRepeat.$dirty && !$v.form.passRepeat.$invalid}"
            >
          <p class="form-warning" v-if="!$v.form.passRepeat.sameAsPassword">
            <span class="svg-icon icons-alert"></span> Şifreler uyuşmuyor.</p>
 
        </div>

        <div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-end">
     
          <ButtonRounded class="float-right my-3 my-md-0"  v-if="!responseMsg"
          Text="Gönder"
          :isRouting="false"
          :disabled="disabled"
          @click.native="submitForm"
          />
        </div>
        <p class="mt-3" v-if="responseMsg">Şifreniz başarıyla değiştirildi. Anasayfaya yönlendiriliyorsunuz...</p>
      </form>


    </div>

    <div class="sides right-side section-wrapper d-none d-md-flex flex-column align-items-center justify-content-between position-relative">
      <div class="h-100 d-flex flex-column justify-content-center">
        <img class="" src="/svg/login.svg">
      </div>
      
      <button @click="sendNewPass" type="button" class="back-button ml-auto d-flex align-items-center">
        <span class="svg-icon icons-back-arrow mr-1"></span>
        <p class="d-flex flex-column align-items-start">
          Geri Dön
        </p>
      </button>
    </div>

  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import {required, email, sameAs, minLength, helpers, requiredIf} from 'vuelidate/lib/validators';
const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach(e => {
    if(e.length < 2){
      isValid = false;
    }
  });
  return isValid;
}


export default {
  name: "ResetPassword",
  components: {
    ButtonRounded, Loading
  },
  props: [ "formValue" ],
  data(){
    return{
      disabled: false,
      isLoading: false,
      fullPage: false,
      form: {
        userMail: '',
        userPass: '',
        passRepeat: '',
      },
      wrongInfo: false,
      responseMsg: false,
      token: '',
      mssg: '',
    }
  },
  validations: {
    form:{
      userMail: {
        required,
        email: email
      },
      userPass: {
        required,
      },
      passRepeat: {
        sameAsPassword: sameAs('userPass')
      },
    }
  },
  mounted(){
    this.createToken();
  },
  methods: {
    closeModal(){
      this.$parent.destroyCarousel();
      this.$parent.closeModal();
     
    },
    slideToPage (event) {
      this.$emit('clicked', event);
    },
    doAjax() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        },1500)
    },
    key: function(event){
      if(event.keyCode === 32){
        event.preventDefault();
      }
    },
    sendNewPass(){
      this.$router.push("/");
    },
    createToken(){
      this.token  = this.$route.params.id;
    },
    submitForm(){
     
      this.$v.$touch();
      if(!this.$v.form.$invalid){
        let params = {}
      
        params["token"] = this.token;
        params["email"] = this.form.userMail;
        params["password"] = this.form.userPass;
        params["password_confirmation"] = this.form.passRepeat;
        this.$api.resetPassword(params).then(response => {
          if(response.success == false){
            this.wrongInfo = true;
            
          }else{
              this.responseMsg = true;
              this.mssg = response;
                setTimeout(() => {
                    this.$router.push("/");
                }, 5000);
          }
        });
       
      }else{
        
      }
    }
  }
}
</script>

<style lang="scss">
.info-bg{
  position: absolute;
  bottom: 0;
  right: -3rem;
  height: 75%;
}

.back-button{
  background: transparent;
  color: #fff;
  .svg-icon{
    width: 1.3rem;
    height: 1.3rem;
  }
}
.form-warn{
    display: flex;
    align-items: center;
    color: var(--orange);
    margin: 5px 0 0 5px;
    font-size: 0.8rem;
    .svg-icon{
      background: var(--orange);
    }
}
.reset-temp{
    height: 100vh !important;
    z-index: 9;
    position: relative;
}

</style>